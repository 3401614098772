import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { uploadJobVideosGql } from "@/api/jobs/uploadJobVideos";
import {
  UploadJobVideos,
  UploadJobVideosVariables,
  UploadJobVideos_uploadJobVideos_GenericSuccess,
} from "@/api/jobs/__generated__/UploadJobVideos";
import { UploadJobVideosInput } from "@/../__generated__/globalTypes";
import { apiErrorCodes } from "@/shared/utils/constants";
import { makeToast } from "@/shared/utils/toast";

export const useJobs = () => {
  const { mutate: uploadJobVideoMutate, loading: uploadJobVideoLoading } =
    useCustomMutation<UploadJobVideos, UploadJobVideosVariables>(
      uploadJobVideosGql
    );

  const uploadJobVideo = async (
    input: UploadJobVideosInput,
    files: {
      videoWithSound?: File;
      videoWithoutSound?: File;
    }
  ) => {
    const response = await uploadJobVideoMutate({
      input,
      videoWithSound: files.videoWithSound,
      videoWithoutSound: files.videoWithoutSound,
    });

    const parsedResponse =
      parseGqlResponse<UploadJobVideos_uploadJobVideos_GenericSuccess>(
        "GenericSuccess",
        response,
        apiErrorCodes.INVALID_INPUT
      );

    console.log("parsedResponse", parsedResponse);

    // Custom error message for invalid job ID
    if (
      parsedResponse.error?.errors[0]?.code?.toString() ===
      apiErrorCodes.INVALID_INPUT.toString()
    ) {
      makeToast(
        "error",
        "Error",
        "Invalid input. The Job ID is not recognized. Please use the corresponding Job ID from the email sent to you."
      );
    }

    return parsedResponse.data;
  };

  return {
    uploadJobVideo,
    uploadJobVideoLoading,
  };
};
