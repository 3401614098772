import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-center my-16" }
const _hoisted_2 = { class: "public-upload-video-page__label-highlight" }
const _hoisted_3 = { class: "public-upload-video-page__label-highlight" }
const _hoisted_4 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginBackground = _resolveComponent("LoginBackground")!
  const _component_BrandIcon = _resolveComponent("BrandIcon")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_InputUpload = _resolveComponent("InputUpload")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    class: "public-upload-video-page pb-32",
    narrow: ""
  }, {
    complexBackground: _withCtx(() => [
      _createVNode(_component_LoginBackground)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BrandIcon, { class: "w-52 h-auto object-contain mb-16" }),
        _createVNode(_component_a_typography_title, { level: 3 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Submit Your Video")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t(
            "Uploading video will take time, depending on the size of the file. Please do not refresh the site while the upload is in progress."
          )), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t(
            "If you encounter network connection issue, you may upload one video at a time. Indicate as with or without background sound accordingly."
          )), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_FormGroup, {
        loading: _ctx.uploadJobVideoLoading,
        disabled: _ctx.formDisabled,
        onFinish: _ctx.handleFinish,
        model: _ctx.formState
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_card, { class: "mb-8" }, {
            default: _withCtx(() => [
              _createVNode(_component_InputText, {
                label: _ctx.t('Job ID'),
                name: "jobId",
                value: _ctx.formState.jobId,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.jobId) = $event)),
                rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
              }, null, 8, ["label", "value", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_card, { class: "mb-8" }, {
            default: _withCtx(() => [
              _createVNode(_component_InputText, {
                label: _ctx.t('Your Name'),
                name: "name",
                value: _ctx.formState.name,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.name) = $event)),
                rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
              }, null, 8, ["label", "value", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_card, { class: "public-upload-video-page__file-upload mb-8" }, {
            default: _withCtx(() => [
              _createVNode(_component_InputUpload, {
                name: "videoWithSound",
                value: _ctx.formState.videoWithSound,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.videoWithSound) = $event)),
                "trigger-label": _ctx.t('Select a video file to upload.'),
                "file-list-label": _ctx.t('Video File'),
                "max-count": 1,
                "max-file-size-in-mb": 1000,
                disabled: _ctx.formDisabled,
                accept: null,
                capture: null,
                "validate-types": ['video/quicktime', 'video/mp4'],
                "extensions-for-label": ['MOV', 'MP4'],
                rules: [_ctx.customVideoRule],
                draggable: ""
              }, {
                label: _withCtx(() => [
                  _createVNode(_component_a_typography_text, { class: "public-upload-video-page__label" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_i18n_t, { keypath: "Upload Video {0} Background Sound" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t("With")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "trigger-label", "file-list-label", "disabled", "rules"]),
              _createVNode(_component_InputUpload, {
                name: "videoWithoutSound",
                value: _ctx.formState.videoWithoutSound,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.videoWithoutSound) = $event)),
                "trigger-label": _ctx.t('Select a video file to upload.'),
                "file-list-label": _ctx.t('Video File'),
                "max-count": 1,
                "max-file-size-in-mb": 1000,
                disabled: _ctx.formDisabled,
                accept: null,
                capture: null,
                "validate-types": ['video/quicktime', 'video/mp4'],
                "extensions-for-label": ['MOV', 'MP4'],
                rules: [_ctx.customVideoRule],
                draggable: ""
              }, {
                label: _withCtx(() => [
                  _createVNode(_component_a_typography_text, { class: "public-upload-video-page__label block" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_i18n_t, { keypath: "Upload Video {0} Background Sound" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t("Without")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_typography_text, { class: "block mb-4" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t(
                  "Do not upload a without-background music video until the QA asks you to do so."
                )), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "trigger-label", "file-list-label", "disabled", "rules"]),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.uploadSuccess)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      "html-type": "button",
                      onClick: _withModifiers(_ctx.resetForm, ["stop"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Upload Another")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : (_openBlock(), _createBlock(_component_a_button, {
                      key: 1,
                      type: "primary",
                      "html-type": "submit",
                      loading: _ctx.uploadJobVideoLoading,
                      disabled: _ctx.formDisabled
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Upload")), 1)
                      ]),
                      _: 1
                    }, 8, ["loading", "disabled"]))
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading", "disabled", "onFinish", "model"])
    ]),
    _: 1
  }))
}