
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import InputUpload from "@/shared/components/Forms/InputUpload.vue";
import BrandIcon from "@/shared/components/Icons/BrandIcon.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { defineComponent, reactive, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import { useJobs } from "@/shared/composables/useJobs";
import LoginBackground from "@/web/views/Login/LoginBackground.vue";
import type { UploadFile } from "ant-design-vue";
import { InternalRuleItem } from "async-validator";
import message from "@/shared/utils/message";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const uploadSuccess = ref(false);

    const formState = reactive({
      jobId: "",
      name: "",
      videoWithSound: [] as UploadFile[],
      videoWithoutSound: [] as UploadFile[],
    });

    const { uploadJobVideo, uploadJobVideoLoading } = useJobs();

    const handleFinish = async () => {
      const response = await uploadJobVideo(
        {
          jobId: formState.jobId,
          name: formState.name,
        },
        {
          videoWithSound: formState.videoWithSound.at(0)?.originFileObj,
          videoWithoutSound: formState.videoWithoutSound.at(0)?.originFileObj,
        }
      );

      if (response?.success) {
        message["success"]({
          content: t(
            `Successfully uploaded video for Job #${formState.jobId}.`
          ),
        });

        uploadSuccess.value = true;
      }
    };

    const resetForm = () => {
      formState.jobId = "";
      formState.videoWithSound = [];
      formState.videoWithoutSound = [];
      uploadSuccess.value = false;
    };

    /** Custom validator for validating if either file field is supplied */
    const validateVideo = async (rule: InternalRuleItem, _: UploadFile[]) => {
      // Only show validation in last field, videoWithoutSound
      if (rule.field !== "videoWithoutSound") {
        return Promise.resolve();
      }

      // If both are empty, reject
      if (
        !formState.videoWithSound.length &&
        !formState.videoWithoutSound.length
      ) {
        return Promise.reject(t("Please upload at least 1 video file."));
      }

      return Promise.resolve();
    };

    /** Custom rule validation for with and without sound fields */
    const customVideoRule = {
      validator: validateVideo,
      required: true,
      trigger: "change",
    };

    return {
      t,
      formState,
      formDisabled: computed(
        () => uploadJobVideoLoading.value || uploadSuccess.value
      ),
      uploadSuccess,
      makeRequiredRule,
      handleFinish,
      resetForm,
      uploadJobVideoLoading,
      customVideoRule,
    };
  },
  components: {
    PageLayout,
    BrandIcon,
    FormGroup,
    InputText,
    InputUpload,
    LoginBackground,
  },
});
