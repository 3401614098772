import gql from "graphql-tag";

export const uploadJobVideosGql = gql`
  mutation UploadJobVideos(
    $input: UploadJobVideosInput!
    $videoWithoutSound: Upload
    $videoWithSound: Upload
  ) {
    uploadJobVideos(
      input: $input
      videoWithoutSound: $videoWithoutSound
      videoWithSound: $videoWithSound
    ) {
      ... on GenericSuccess {
        success
        message
        __typename
      }

      ... on ResponseErrors {
        errors {
          __typename
          code
          message
          displayMessage
        }
      }
    }
  }
`;
